import Axios, { AxiosError, isAxiosError } from 'axios';
import { ChartData, ChartMetaInfo, ChartTemplateContent, ResolutionString } from 'charting_library/charting_library';
import { AppConfig, hasMessage, isTradingDay, utcToDate } from '@cometph/frontend-core/helpers';
import {
  AccountValueItem,
  Alert,
  Candle,
  mapAccountValueItem,
  mapWsToCandle,
  WsAccountValueItem,
  WsCandle,
} from '@cometph/frontend-core/api';
import { routeTp } from 'common/route';

export const apiClient = Axios.create({
  baseURL: AppConfig.BASE_API_URL,
  paramsSerializer: {
    indexes: null,
  },
});

apiClient.interceptors.response.use(null, (error) => {
  if (isAxiosError(error) && hasMessage(error.response?.data)) {
    error.message = error.response!.data.message;

    throw error;
  }
});

export const addAuthorizationBearerToken = (accessToken: string) => {
  apiClient.defaults.headers.Authorization = `Bearer ${accessToken}`;
};

export const removeAuthorizationBearerToken = () => {
  apiClient.defaults.headers.Authorization = null;
};

type GetBarsParams = {
  timeFrame: ResolutionString;
  symbol: string;
  fromTimeStamp: number;
  toTimeStamp: number;
};

type GetBarsResponse = {
  data: WsCandle[];
  hasMoreData: boolean;
};

type GetBarsResult = { candles: Candle[]; hasMoreData: boolean };

type CreateAlertParams = {
  symbol: string;
  price: number;
};

type GetAccountValuesParams = {
  from: number;
  to: number;
  period: string;
};

type GetAccountValuesResponse = {
  data: WsAccountValueItem[];
  hasMoreData: boolean;
};

type BrankasDepositResponse = {
  transaction_id: string;
  redirect_uri: string;
  short_redirect_uri: string;
};

export const api = {
  setIsFavourite: (symbol: string, isFavourite: boolean): Promise<void> =>
    apiClient.put(`watchlist/set_favorite`, {
      symbol,
      isFavourite,
    }),
  getBars: (params: GetBarsParams): Promise<GetBarsResult> =>
    apiClient
      .get<GetBarsResponse>('get_bars', {
        params,
      })
      .then(({ data: { data, hasMoreData } }) => {
        let candles = data.map((candle) => mapWsToCandle(candle));
        if (params.timeFrame === 'D') {
          candles = candles.filter((candle) => {
            return isTradingDay(utcToDate(candle.time));
          });
        }
        return { candles, hasMoreData };
      }),
  getAlerts: () => apiClient.get<{ data: Alert[] }>('alerts').then((x) => x.data.data),
  createAlert: (data: CreateAlertParams) => apiClient.post<{ data: Alert }>('alerts', data).then((x) => x.data.data),
  deleteAlert: (id: number) =>
    apiClient.delete<Alert>(`alerts`, {
      params: { id },
    }),
  markAlertsAsSeen: (ids: number[]) => apiClient.put(`alerts/seen`, { ids }),
  validateCaptchaToken: (token: string) =>
    apiClient
      .post<{
        success: boolean;
        /** timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ) */
        challenge_ts: string;
        /** the hostname of the site where the reCAPTCHA was solved */
        hostname: string;
        'error-codes'?: string[];
      }>('recaptcha', { response: token })
      .then((x) => x.data),
  getTemplateNames: () => apiClient.get<string[]>('tv/templates').then((x) => x.data),
  deleteTemplate: (templateName: string): Promise<void> => apiClient.delete('tv/templates', { params: { template_name: templateName } }),
  saveTemplate: (newName: string, theme: ChartTemplateContent): Promise<void> =>
    apiClient.post('tv/templates', {
      newName,
      theme,
    }),
  getTemplateData: (templateName: string) =>
    apiClient
      .get<ChartTemplateContent>('tv/templates/chart-data', {
        params: {
          template_name: templateName,
        },
      })
      .then((x) => ({
        content: x.data,
      }))
      .catch((e: AxiosError) => {
        if (e.response?.status === 404) {
          return {};
        }

        throw e;
      }),
  getChartMetadata: () => apiClient.get<ChartMetaInfo[]>('tv/chart-data/meta-info').then((x) => x.data),
  getChartData: (chartId: string | number) =>
    apiClient.get<ChartData>('tv/chart-data', { params: { chart_id: chartId } }).then((x) => JSON.stringify(x.data)),
  saveChartData: (data: ChartData) => apiClient.post<string>('tv/chart-data', data).then((x) => x.data),
  deleteChartData: (chartId: string | number): Promise<void> => apiClient.delete('tv/chart-data', { params: { id: chartId } }),
  getPortfolioAccountValues: ({
    from,
    to,
    period,
  }: GetAccountValuesParams): Promise<{
    hasMoreData: boolean;
    data: AccountValueItem[];
  }> =>
    apiClient
      .get<GetAccountValuesResponse>('portfolio/account_values', {
        params: {
          fromTimeStamp: from,
          toTimeStamp: to,
          period,
        },
      })
      .then((x) => ({
        ...x.data,
        data: x.data.data.map(mapAccountValueItem),
      })),
  mapUserId: (data: { accountCode: string }) => apiClient.post('aaa/user-data/map-user-id', data),
  createBrankasTransaction: (amount: number) =>
    apiClient
      .post<BrankasDepositResponse>('brankas/create_transaction', {
        amount,
        type: 'DEPOSIT',
        return_url: location.origin + routeTp.portfolio.$abs(),
      })
      .then(({ data }) => data),
};
