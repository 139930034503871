import { css, DefaultTheme } from 'styled-components';
import { CometColors } from 'styles/theme';

export const styledSpace = (k: number) => `${k / 4}rem`;
export const styledColor = (color: keyof CometColors) => (props: { theme: DefaultTheme }) => props.theme.colors[color];

export const buttonNoStyle = css`
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  outline: none !important;
  background: none;
`;

export const textRegular = css`
  font-size: 14px;
  line-height: ${styledSpace(5)};
  font-weight: 500;
`;

export const textGradientPurpleBlue = css`
  background: linear-gradient(90.52deg, #9d5bf0 30%, #3f84ec 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const gradientTitle = css`
  ${textRegular};
  ${textGradientPurpleBlue};
  font-weight: 700;
  text-transform: uppercase;
`;
