import { Amplify } from 'aws-amplify';

export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    },
  });
};

export const configureAmplifyAdmin = () => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_ADMIN_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_ADMIN_APP_CLIENT_ID,
    },
  });
};
