import React, { useEffect } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider, useLocation } from 'react-router-dom';
import { Login } from 'modules/login/Login';
import { Dashboard } from 'modules/dashboard/Dashboard';
import { AppWsContextProvider } from 'common/contexts/AppWsContext';
import styles from './App.module.scss';
import { Signup } from 'modules/signup/Signup';
import { ForgotPassword } from 'modules/forgot-password/ForgotPassword';
import { AppHeader } from 'components/AppHeader/AppHeader';
import { TradeCarousel } from 'modules/dashboard/components/TradeCarousel/TradeCarousel';
import classNames from 'classnames';
import { ScrollableWrapper } from 'components/ScrollableWrapper';
import { updateIsApiAuthorized, useAppIsCarouselHidden, useIsAppReady } from 'common/store/appReducer';
import { PortfolioWsContextProvider } from 'common/contexts/PortfolioWsContext';
import { Portfolio } from 'modules/portfolio/Portfolio';
import { routeTp } from 'common/route';
import { useAuth } from '@cometph/frontend-core/contexts';
import { PortfolioActivity } from 'modules/portfolio/components/Activity/PortfolioActivity';
import { PortfolioActivityTrades } from 'modules/portfolio/components/Activity/PortfolioActivityTrades';
import { PortfolioActivityDeposits } from 'modules/portfolio/components/Activity/PortfolioActivityDeposits';
import { PortfolioActivityWithdrawals } from 'modules/portfolio/components/Activity/PortfolioActivityWithdrawals';
import { PortfolioActivityDividends } from 'modules/portfolio/components/Activity/PortfolioActivityDividends';
import { addAuthorizationBearerToken, removeAuthorizationBearerToken } from 'api/api';
import { configureAmplifyAdmin } from 'configureAmplify';
import { AdminLogin } from 'modules/admin/login/AdminLogin';
import { AdminRoot } from 'modules/admin/AdminRoot';
import { useDispatch } from 'react-redux';
import { BrankasTransactionStatus } from '@cometph/frontend-core/api';
import { useSnackbar } from 'notistack';

const Root = () => {
  const { isTokenInvalid, isTokenValid, token } = useAuth();
  const isReady = useIsAppReady();
  const isCarouselHidden = useAppIsCarouselHidden();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const queryParams = new URLSearchParams(location.href.split('?')[1] ?? '');
  const brankasTransactionStatus = queryParams.get('status');
  const brankasTransactionId = queryParams.get('transaction_id');

  useEffect(() => {
    if (!!brankasTransactionStatus && !!brankasTransactionId) {
      if (Number(brankasTransactionStatus) !== BrankasTransactionStatus.Success) {
        enqueueSnackbar('Your transaction has failed.', {
          variant: 'error',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      } else {
        enqueueSnackbar('Your funds have successfully been added to your trading account.', {
          variant: 'success',
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
        });
      }
    }
  }, [brankasTransactionId, brankasTransactionStatus, enqueueSnackbar]);

  useEffect(() => {
    if (isTokenValid) {
      addAuthorizationBearerToken(token!);
      dispatch(updateIsApiAuthorized(true));
    } else if (isTokenInvalid) {
      removeAuthorizationBearerToken();
      dispatch(updateIsApiAuthorized(false));
    }
  }, [dispatch, isTokenInvalid, isTokenValid, token]);

  return isTokenInvalid ? (
    <Navigate to={routeTp.auth.$abs()} />
  ) : pathname === '/' ? (
    <Navigate to={routeTp.dashboard.$abs()} replace />
  ) : (
    <AppWsContextProvider>
      <AppHeader />
      <ScrollableWrapper className={styles.scrollWrapper}>
        {!isCarouselHidden && <TradeCarousel />}
        <main className={classNames([styles.appWrapper, !isReady && styles.loading])}>
          <Outlet />
        </main>
      </ScrollableWrapper>
    </AppWsContextProvider>
  );
};

const router = createBrowserRouter([
  {
    path: routeTp.$abs(),
    element: <Root />,
    children: [
      {
        path: routeTp.dashboard.$abs(),
        element: <Dashboard />,
      },
      {
        path: routeTp.portfolio.$abs(),
        element: (
          <PortfolioWsContextProvider>
            <Portfolio />
          </PortfolioWsContextProvider>
        ),
        children: [
          {
            path: routeTp.portfolio.activity.$abs(),
            element: <PortfolioActivity />,
            children: [
              {
                path: routeTp.portfolio.activity.trades.$abs(),
                element: <PortfolioActivityTrades />,
              },
              {
                path: routeTp.portfolio.activity.deposits.$abs(),
                element: <PortfolioActivityDeposits />,
              },
              {
                path: routeTp.portfolio.activity.withdrawals.$abs(),
                element: <PortfolioActivityWithdrawals />,
              },
              {
                path: routeTp.portfolio.activity.dividends.$abs(),
                element: <PortfolioActivityDividends />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: routeTp.auth.$abs(),
    element: <Login />,
  },
  {
    path: routeTp.register.$abs(),
    element: <Signup />,
  },
  {
    path: routeTp['forgot-password'].$abs(),
    element: <ForgotPassword />,
  },
  {
    path: routeTp.admin.$abs(),
    loader: () => {
      configureAmplifyAdmin();
      return {};
    },
    element: <AdminRoot />,
    children: [
      {
        path: routeTp.admin.login.$abs(),
        element: <AdminLogin />,
      },
    ],
  },
]);

export const App = () => {
  return (
    <div className={styles.appWrapper}>
      <RouterProvider router={router} />
    </div>
  );
};
