import React, { memo, useEffect } from 'react';
import { configureAmplify } from 'configureAmplify';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { routeTp } from 'common/route';

export const AdminRoot = memo(function AdminRoot() {
  const location = useLocation();
  const isAdminRoot = location.pathname === routeTp.admin.$abs();

  useEffect(() => () => configureAmplify(), []);

  return isAdminRoot ? <Navigate to={routeTp.admin.login.$abs()} /> : <Outlet />;
});
