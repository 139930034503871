import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { api } from 'api/api';
import { pushAlerts, useIsApiAuthorized } from 'common/store/appReducer';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Alert, handleWsResult, isWsAlert, useCommonWsSubscribe, WsEventHandler, WsMethod } from '@cometph/frontend-core/api';
import { AppConfig, getAlertText } from '@cometph/frontend-core/helpers';
import { useWsSendJsonMessageWithToken } from 'common/hooks/useWsSendJsonMessageWithToken';
import { useAuth } from '@cometph/frontend-core/contexts';

function sendDeviceNotification(alert: Alert) {
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    new Notification(getAlertText(alert));
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        new Notification(getAlertText(alert));
      }
    });
  }
}

export const useHandleAlerts = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isApiAuthorized = useIsApiAuthorized();

  const { token, isTokenValid } = useAuth();

  useEffect(() => {
    if (isApiAuthorized) {
      api.getAlerts().then((res) => dispatch(pushAlerts(res)));
    }
  }, [dispatch, isApiAuthorized, isTokenValid]);

  const handleMessage: WsEventHandler = useCallback(
    (data) => {
      handleWsResult(isWsAlert, ({ data: alert }) => {
        dispatch(pushAlerts([alert]));
        enqueueSnackbar(getAlertText(alert), {
          variant: 'symbolAlert',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          persist: true,
        });
        if (!document.hasFocus()) {
          sendDeviceNotification(alert);
        }
      })(data);
    },
    [dispatch, enqueueSnackbar]
  );

  const { sendJsonMessage: _sendJsonMessage, readyState } = useWebSocket(
    `${AppConfig.ALERTS_WS_URL}?Authorization=${token}`,
    {
      onMessage: (event) => {
        handleMessage(JSON.parse(event.data));
      },
    },
    isTokenValid
  );

  const sendJsonMessage = useWsSendJsonMessageWithToken(_sendJsonMessage, token);

  useCommonWsSubscribe(WsMethod.Alerts, { sendJsonMessage, isConnected: readyState === ReadyState.OPEN });
};
