// initApp import MUST on top of this file
import './initApp';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { App } from 'App';
import { store } from 'common/store/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { cometTheme, darkTheme } from 'styles/theme';
import { SymbolAlert } from 'components/SymbolAlert/SymbolAlert';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from '@cometph/frontend-core/contexts';
import useIsTabVisible from 'common/hooks/useIsTabVisible';

const AppRoot = () => {
  const isTabVisible = useIsTabVisible();
  return (
    <ThemeProvider theme={cometTheme}>
      <StyledThemeProvider theme={darkTheme}>
        <AuthProvider isAppActive={isTabVisible}>
          <SnackbarProvider maxSnack={3} autoHideDuration={5000} Components={{ symbolAlert: SymbolAlert }}>
            <Provider store={store}>
              <App />
            </Provider>
          </SnackbarProvider>
        </AuthProvider>
      </StyledThemeProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
