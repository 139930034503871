import React, { ComponentProps, FC } from 'react';
import { SvgIcon } from '@mui/material';
import { useTheme } from 'styled-components';

type Props = ComponentProps<typeof SvgIcon>;

export const IconLink: FC<Props> = (props) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
        d="M2.65227 7.17191L9.82442 -0.000243187L11.0029 1.17826L3.83077 8.35042H10.1522V10.0171L0.985605 10.0171L0.985605 0.850424H2.65227L2.65227 7.17191Z"
        fill={theme.colors.secondaryLight}
      />
    </SvgIcon>
  );
};
