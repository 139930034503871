import React, { memo, useCallback } from 'react';
import { Button } from 'components/Button/Button';
import styles from './DashboardWatchlistAlerts.module.scss';
import { DashboardWatchlistAlertsForm, DashboardWatchlistAlertsFormSchema } from './components/Form/DashboardWatchlistAlertsForm';
import { api } from 'api/api';
import { useDispatch } from 'react-redux';
import { pushAlerts, useAppPendingAlerts } from 'common/store/appReducer';
import { IconBellSolid } from 'assets/icons/IconBellSolid';
import { DashboardWatchlistAlertsList } from './components/List/DashboardWatchlistAlertsList';
import { AppConfig } from '@cometph/frontend-core/helpers';
import { useFlag } from '@cometph/frontend-core/hooks';
import { CometModal } from 'components/CometModal';

export const DashboardWatchlistAlerts = memo(function DashboardWatchlistAlerts() {
  const alerts = useAppPendingAlerts();
  const [isOpen, openModal, closeModal] = useFlag();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data: DashboardWatchlistAlertsFormSchema) => {
      return api.createAlert(data).then((alert) => dispatch(pushAlerts([alert])));
    },
    [dispatch]
  );

  return (
    <>
      <Button onClick={openModal} className={styles.button} buttonStyle="primary">
        <IconBellSolid className={styles.icon} /> Alerts
      </Button>
      <CometModal isOpen={isOpen} onClose={closeModal}>
        <DashboardWatchlistAlertsForm disabled={alerts.length >= AppConfig.MAX_ALERTS_NUMBER} onSubmit={handleSubmit} />
        <DashboardWatchlistAlertsList />
      </CometModal>
    </>
  );
});
