import React, { ComponentProps, FC, ReactNode, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldLabel } from '../FieldLabel/FieldLabel';
import { v4 as guid } from 'uuid';
import { TextInput } from '../TextInput/TextInput';
import classNames from 'classnames';
import formStyles from '../../../styles/_form.module.scss';

type Props = {
  name: string;
  label: string;
  placeholder?: string;
  endElement?: ReactNode;
  startElement?: ReactNode;
} & Pick<ComponentProps<typeof TextInput>, 'placeholder' | 'type' | 'autoComplete' | 'className' | 'readOnly'>;

export const TextField: FC<Props> = ({ name, label, className, endElement, startElement, ...inputProps }) => {
  const id = useRef(`${name}_${guid()}`);
  const methods = useFormContext();
  const error = methods.formState.errors[name]?.message?.toString();
  return (
    <div
      className={classNames([
        formStyles.container,
        !!error && formStyles.hasError,
        !!endElement && formStyles.hasEndElement,
        !!startElement && formStyles.hasStartElement,
      ])}
    >
      <FieldLabel text={label} inputId={id.current} />
      <div className={formStyles.inputWrapper}>
        {!!startElement && <div className={formStyles.startElement}>{startElement}</div>}
        <TextInput
          {...inputProps}
          className={classNames([formStyles.input, className])}
          {...methods.register(name, { valueAsNumber: inputProps.type === 'number' })}
        />
        {!!endElement && <div className={formStyles.endElement}>{endElement}</div>}
      </div>
      {!!error && <div className={formStyles.error}>{error}</div>}
    </div>
  );
};
