import React, { memo, useEffect, useMemo, useState } from 'react';
import { PortfolioDepositModalOtherOption } from 'modules/portfolio/components/AccountValue/DepositModal/PortfolioDepositModal';
import styled, { css } from 'styled-components';
import { buttonNoStyle, gradientTitle, styledColor, styledSpace, textRegular } from 'styles/mixins';
import { BDOPayOverCounterImg, MetrobankPayOverCounterImg, UnionbankPayOverCounterImg } from 'assets/images';

type Props = {
  option: PortfolioDepositModalOtherOption;
};

export const PortfolioDepositModalOtherOptions = memo(function PortfolioDepositModalOtherOptions({ option }: Props) {
  const [isOverTheCounter, setIsOverTheCounter] = useState(false);

  useEffect(() => {
    setIsOverTheCounter(false);
  }, [option]);

  const overTheCounterButton = useMemo(() => {
    return (
      <OverTheCounterButton onClick={() => setIsOverTheCounter((c) => !c)}>
        {isOverTheCounter ? <>&larr; Go back</> : 'Pay over the counter'}
      </OverTheCounterButton>
    );
  }, [isOverTheCounter]);

  const footnote =
    option === PortfolioDepositModalOtherOption.OFWRemittance
      ? null
      : 'You will receive a confirmation email from ' + option + ' if the transfer is successful';
  let content: React.ReactNode;

  if (isOverTheCounter) {
    switch (option) {
      case PortfolioDepositModalOtherOption.BDO:
        content = (
          <>
            <MainList>
              <MainListItem>
                Go to the nearest branch and fill out the <b>Cash Transaction Slip</b>
              </MainListItem>
              <SlipImg alt="BDO Cash Transaction Slip" src={BDOPayOverCounterImg}></SlipImg>
            </MainList>
          </>
        );
        break;
      case PortfolioDepositModalOtherOption.Metrobank:
        content = (
          <>
            <MainList>
              <MainListItem>
                Go to the nearest branch and fill out the <b>Payment Slip</b>
              </MainListItem>
              <SlipImg alt="Metrobank Payment Slip" src={MetrobankPayOverCounterImg}></SlipImg>
            </MainList>
          </>
        );
        break;
      case PortfolioDepositModalOtherOption.Unionbank:
        content = (
          <>
            <MainList>
              <MainListItem>
                Go to the nearest branch and fill out the <b>Bills Payment Slip</b>
              </MainListItem>
              <SlipImg alt="Unionbank Bills Payment Slip" src={UnionbankPayOverCounterImg}></SlipImg>
            </MainList>
          </>
        );
        break;
      default:
        content = <></>;
    }

    return (
      <ContentWrapper>
        <TitleWrapper>
          <Title>pay over the counter</Title>
          {overTheCounterButton}
        </TitleWrapper>
        {content}
        <Footnote>{footnote}</Footnote>
      </ContentWrapper>
    );
  }

  switch (option) {
    case PortfolioDepositModalOtherOption.BDO:
      content = (
        <>
          <TitleWrapper>
            <Title>bdo online</Title>
            {overTheCounterButton}
          </TitleWrapper>
          <MainList>
            <MainListItem>
              Enroll the <b>Company/Biller</b> first
            </MainListItem>
            <SecondaryList>
              <li>
                Log into your <a href="https://bdo.com.ph">BDO Online</a> account
              </li>
              <li>
                Click <b>Enrollment</b> &rarr; <b>Company/Biller</b> &rarr; <b>Enroll</b>
              </li>
              <li>
                Fill out the <b>Enrollment details</b>
              </li>
              <li>
                Input the following details
                <DetailsList>
                  <DetailsListLabel>Company/Biller Name</DetailsListLabel>
                  <DetailsListValue>AAA Equities</DetailsListValue>
                  <DetailsListLabel>Subscriber Number</DetailsListLabel>
                  <DetailsListPlaceholder>Your AAA Username</DetailsListPlaceholder>
                  <DetailsListLabel>Subscriber Name</DetailsListLabel>
                  <DetailsListPlaceholder>Your full name</DetailsListPlaceholder>
                </DetailsList>
              </li>
              <li>Select your preferred channel for enrollment</li>
            </SecondaryList>
            <MainListItem>
              Click <b>Pay Bills and Reload</b> &rarr; <b>Bills Payment</b> &rarr; <b>Pay Bills</b>
            </MainListItem>
            <MainListItem>
              Fill out page with appropriate details, then click <b>Submit</b>
            </MainListItem>
          </MainList>
        </>
      );
      break;

    case PortfolioDepositModalOtherOption.Metrobank:
      content = (
        <>
          <TitleWrapper>
            <Title>metrobank online</Title>
            {overTheCounterButton}
          </TitleWrapper>
          <MainList>
            <MainListItem>
              Log into your <a href="https://onlinebanking.metrobank.com.ph/signin">Metrobank Direct</a> account
            </MainListItem>
            <MainListItem>
              Select <b>Pay Bills</b>
            </MainListItem>
            <MainListItem>
              Under <b>Special Bill</b> and Category <b>Investments</b>, choose <b>AAA Southeast Equities Inc.</b> from the dropdown
            </MainListItem>
            <MainListItem>
              Input the following details
              <DetailsList>
                <DetailsListLabel>Subscriber Number</DetailsListLabel>
                <DetailsListPlaceholder>Your AAA Username</DetailsListPlaceholder>
                <DetailsListLabel>Reference Number</DetailsListLabel>
                <DetailsListPlaceholder>Your AAA Username</DetailsListPlaceholder>
              </DetailsList>
            </MainListItem>
            <MainListItem>
              Choose the <b>Account Number</b> where you will source the funds
            </MainListItem>
            <MainListItem>
              Enter the <b>Amount</b> you wish to transfer
            </MainListItem>
            <MainListItem>
              Select <b>Immediate Payment</b>
            </MainListItem>
            <MainListItem>
              Click <b>Confirm</b>
            </MainListItem>
          </MainList>
        </>
      );
      break;

    case PortfolioDepositModalOtherOption.Unionbank:
      content = (
        <>
          <TitleWrapper>
            <Title>Unionbank online</Title>
            {overTheCounterButton}
          </TitleWrapper>
          <MainList>
            <MainListItem>
              Log into your <a href="https://online.unionbankph.com/online-banking/login#!/login">Unionbank Online</a> account
            </MainListItem>
            <MainListItem>
              Select <b>Pay Bills</b>
            </MainListItem>
            <MainListItem>
              Under <b>Select Biller</b> choose <b>AAA Southeast Equities Inc.</b>
            </MainListItem>
            <MainListItem>
              Input the following details
              <DetailsList>
                <DetailsListLabel>Depositor&apos;s Name</DetailsListLabel>
                <DetailsListPlaceholder>ex: Juan dela Cruz</DetailsListPlaceholder>
                <DetailsListLabel>Username</DetailsListLabel>
                <DetailsListPlaceholder>Your AAA Username</DetailsListPlaceholder>
              </DetailsList>
            </MainListItem>
            <MainListItem>
              Select your <b>Unionbank Account</b> where the funds will be sourced
            </MainListItem>
            <MainListItem>
              Click <b>Confirm</b>
            </MainListItem>
          </MainList>
        </>
      );
      break;

    case PortfolioDepositModalOtherOption.BPI:
      content = (
        <>
          <TitleWrapper>
            <Title>bpi online</Title>
          </TitleWrapper>
          <MainList>
            <MainListItem>
              Enroll the <b>Company/Biller</b> first
            </MainListItem>
            <SecondaryList>
              <li>
                Log into your <a href="https://online.bpi.com.ph/login">BPI Online</a> account
              </li>
              <li>
                Go to <b>Other Services</b> on the sidebar menu
              </li>
              <li>
                Select <b>Recipients</b>
              </li>
              <li>
                Click on <b>Add New Recipient</b>
              </li>
              <li>
                Under the <b>Recipient</b> dropdown menu, select <b>Billers</b>
              </li>
              <li>
                Under the <b>Biller</b> dropdown menu, select <b>AAA Southeast Equities Inc.</b>
              </li>
              <li>
                Input the following details
                <DetailsList>
                  <DetailsListLabel>Reference Number</DetailsListLabel>
                  <DetailsListPlaceholder>Your AAA Username</DetailsListPlaceholder>
                </DetailsList>
              </li>
              <li>Select your preferred channel for enrollment</li>
            </SecondaryList>
            <MainListItem>
              Go to <b>Payments/Load</b> on the sidebar menu
            </MainListItem>
            <MainListItem>
              Select your <b>account</b> that will be debited
            </MainListItem>
            <MainListItem>
              Enter the <b>amount</b> you want to transfer
            </MainListItem>
            <MainListItem>
              Select <b>AAA Southeast Equities</b> from the <b>Biller</b> dropdown menu
            </MainListItem>
          </MainList>
        </>
      );
      break;

    case PortfolioDepositModalOtherOption.OFWRemittance:
      content = (
        <>
          <TitleWrapper>
            <Title>ofw remittance</Title>
          </TitleWrapper>
          <MainList>
            <MainListItem>Use any remittance service of your choice to make a transfer to the account below</MainListItem>
            <SecondaryList>
              <DetailsList>
                <DetailsListLabel>Bank name</DetailsListLabel>
                <DetailsListValue>METROBANK</DetailsListValue>
                <DetailsListLabel>Account number</DetailsListLabel>
                <DetailsListValue>532-7-53200547-3</DetailsListValue>
                <DetailsListLabel>Account name</DetailsListLabel>
                <DetailsListValue>AAA SOUTHEAST EQUITIES, INC.</DetailsListValue>
                <DetailsListLabel>Reference number (if available)</DetailsListLabel>
                <DetailsListPlaceholder>Your AAA Username</DetailsListPlaceholder>
              </DetailsList>
            </SecondaryList>
          </MainList>
          <OFWBanner>
            <b>*IMPORTANT:</b> Kindly send a photo or screenshot of your fund transfer to{' '}
            <OFWBannerLink href="mailto:support@aaa-equities.com.ph">support@aaa-equities.com.ph</OFWBannerLink> to ensure the timely
            crediting of your trading account.
          </OFWBanner>
        </>
      );
      break;

    default:
      content = <></>;
  }

  return (
    <ContentWrapper>
      {content}
      {footnote && <Footnote>{footnote}</Footnote>}
    </ContentWrapper>
  );
});

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 462px;
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Title = styled.h2`
  ${gradientTitle};
  font-size: 18px;
  margin: 0 0 ${styledSpace(4)};
`;

const OverTheCounterButton = styled.button`
  ${buttonNoStyle};
  ${textRegular};
  color: ${styledColor('secondary')};
  text-decoration: underline;
  cursor: pointer;
  margin: 0 0 ${styledSpace(4)};
`;

const MainListItem = styled.li`
  ${textRegular};
  font-size: ${styledSpace(3.5)};
  line-height: ${styledSpace(5)};
  font-weight: 400;
  position: relative;

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -${styledSpace(8)};
    top: -${styledSpace(0.5)};
    content: counter(item);
    counter-increment: item;
    font-size: ${styledSpace(3)};
    line-height: ${styledSpace(5)};
    font-weight: 700;
    border-radius: 4px;
    background-color: ${styledColor('secondaryLighter')};
    width: ${styledSpace(6)};
    height: ${styledSpace(6)};
  }

  &::marker {
    display: none;
  }
`;

const MainList = styled.ol`
  display: flex;
  flex-direction: column;
  margin: 0 0 ${styledSpace(6)} ${styledSpace(9)};
  gap: ${styledSpace(3)};
  list-style-type: none;
  counter-reset: item;
  padding: 0;
`;

const SecondaryList = styled.ol`
  ${textRegular};
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: ${styledSpace(2)};
  padding: ${styledSpace(3)};
  padding-inline-start: ${styledSpace(8)};
  background-color: ${styledColor('backgroundDark')};
  border-radius: 8px;
`;

const DetailsList = styled.dl`
  display: grid;
  grid-template-columns: max-content auto;

  dt {
    grid-column-start: 1;
  }

  dd {
    grid-column-start: 2;
  }
`;

const detailsListText = css`
  font-size: ${styledSpace(2.5)};
  line-height: ${styledSpace(5)};
  font-weight: 400;
`;

const DetailsListLabel = styled.dt`
  ${detailsListText};
  color: ${styledColor('textDark')};

  &::after {
    content: ':';
  }
`;

const DetailsListValue = styled.dd`
  ${detailsListText};
  margin-inline-start: ${styledSpace(1)};
  font-weight: 700;
  color: ${styledColor('text')};
`;

const DetailsListPlaceholder = styled.dd`
  ${detailsListText};
  margin-inline-start: ${styledSpace(1)};
  font-weight: 500;
  color: ${styledColor('textDarker')};

  &::before {
    content: '(';
  }

  &::after {
    content: ')';
  }
`;

const Footnote = styled.div`
  ${textRegular};
  color: ${styledColor('textDarker')};
  font-style: italic;

  &::before {
    content: '*';
  }
`;

const SlipImg = styled.img`
  align-self: stretch;
  max-height: 400px;
  object-fit: contain;
`;

const OFWBanner = styled.div`
  ${textRegular};
  font-weight: 400;
  color: ${styledColor('textBlack')};
  border-radius: 4px;
  background-color: ${styledColor('warning')};
  padding: ${styledSpace(3)};
`;

const OFWBannerLink = styled.a`
  color: ${styledColor('textBlack')};
  font-weight: 700;
`;
