import { memo } from 'react';
import styled from 'styled-components';
import { buttonNoStyle, styledColor, styledSpace } from 'styles/mixins';

type Option<T> = {
  value: T;
} & (T extends string ? { label?: string } : { label: string });

type Props<T> = {
  options: Option<T>[];
  value: T;
  onSelect: (value: T) => void;
};

function SidebarSelectComponent<T extends string | number>({ onSelect, options, value }: Props<T>) {
  return (
    <Container>
      {options.map((option) => (
        <OptionButton key={option.label ?? option.value} isActive={value === option.value} onClick={() => onSelect(option.value)}>
          {option.label ?? option.value}
        </OptionButton>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${styledSpace(1)};
  align-items: stretch;
`;

const OptionButton = styled.button<{ isActive: boolean }>`
  ${buttonNoStyle};
  cursor: pointer;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? styledColor('backgroundDark')(props) : 'transparent')};
  color: ${(props) => (props.isActive ? styledColor('text')(props) : styledColor('textDark')(props))};
  font-weight: ${(props) => (props.isActive ? 700 : 400)};
  text-align: left;
  padding: ${styledSpace(2)} ${styledSpace(3)};
  font-size: 14px;
  line-height: ${styledSpace(5)};
`;

export const SidebarSelect = memo(SidebarSelectComponent) as typeof SidebarSelectComponent;
