import React, { memo } from 'react';
import { Login } from 'modules/login/Login';

const successRedirect = (token: string) => {
  window.location.href = process.env.REACT_APP_ADMIN_LOGIN_REDIRECT_URL + '?Authorization=' + token;
  return null;
};

export const AdminLogin = memo(function AdminLogin() {
  return <Login onValidToken={successRedirect} withoutSignup />;
});
